
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import * as atlas from "azure-maps-control";

export default defineComponent({
  components: { Header },

  computed: {
    finalMessage() {
      const s = (this as any).$store;
      return s.state.finalMessage;
    }
  },

  mounted() {
    const { Name, LogoUrl, Latitude, Longitude} = this.$route.params;
    const lng: number = parseFloat(Longitude as string)
    const ltd: number = parseFloat(Latitude as string)
    
    
    const map = new atlas.Map(this.$el.querySelector(".map"), {
      center: [lng, ltd],
      zoom: 14,
      autoResize: true,
      language: "en-US",
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: "s9AyVT1rlwKIxeTJObR1VGtsE945QNmx8ubt1s8pNw4",
      },
    });
    map.markers.add([
      new atlas.HtmlMarker({
        htmlContent: `<img class="map-image" src="${LogoUrl}"/><p class="map-name">${Name}</p>`,
        position: [lng, ltd],
      }),
    ]);
  },
});
